import {
    preStudySurveyPath,
    ballSortDirectionPath,
    ballSortPracticePath,
    ballSortReadyPath,
    ballSortExamplePath,
    ballSort1Path,
    ballSort2Path,
    ballSort3Path,
    ballSort4Path,
    ballSort5Path,
    difficultySurvey1Path,
    difficultySurvey2Path,
    difficultySurvey3Path,
    difficultySurvey4Path,
    difficultySurvey5Path,
    difficultySurvey6Path,
    difficultySurvey7Path,
    difficultySurvey8Path,
    difficultySurvey9Path,
    difficultySurvey10Path,
    difficultySurvey11Path,
    difficultySurvey12Path,
    difficultySurvey13Path,
    difficultySurvey14Path,
    difficultySurvey15Path,
    finalSurvey1Path,
    ifDirectionPath,
    ifExamplePath,
    ifPracticePath,
    ifReadyPath,
    if1Path,
    if2Path,
    if3Path,
    if4Path,
    if5Path,
    finalSurvey2Path,
    nonogramDirectionPath,
    nonogramPracticePath,
    nonogramReadyPath,
    nonogram1Path,
    nonogram2Path,
    nonogram3Path,
    nonogram4Path,
    nonogram5Path,
    finalSurvey3Path,
    nonogramExamplePath,
} from "./config/redirectPath"

// import * as React from "react"
import { useState, useEffect } from "react"
import { useLocation, Routes, Route } from "react-router-dom"

/**
import Logo from "./components/common/logo/Logo"
import Footer from "./components/footer/Footer"
import Navbar from "./components/navbar/Navbar"
import BigTitle from "./components/common/bigtitle/BigTitle"
import ParticlesBG from "./components/common/particles/ParticlesBG"
 */
import GridWrapper from "./components/common/gridwrapper/GridWrapper"
import AppBarC from "./components/common/appbar/AppBarC"
/**
import Maze from "./pages/maze/Maze"
 */
import NoIncognito from "./pages/direction/NoIncognito"
import NoPhone from "./pages/direction/NoPhone"

import BallSortPractice from "./components/ballsort/BallSortPractice"
import BallSortReady from "./components/ballsort/BallSortReady"
import BallSortExample from "./components/ballsort/BallSortExample"

import BallSort4 from "./components/ballsort/BallSort4"
import BallSort5 from "./components/ballsort/BallSort5"
import BallSort6 from "./components/ballsort/BallSort6"
import BallSort7 from "./components/ballsort/BallSort7"
import BallSort8 from "./components/ballsort/BallSort8"

import IfExample from "./components/isometric/IsometricExample"
import IfPractice from "./components/isometric/IsometricPractice"
import IsometricReady from "./components/isometric/IsometricReady"
import Isometric1 from "./components/isometric/Isometric1"
import Isometric2 from "./components/isometric/Isometric2"
import Isometric3 from "./components/isometric/Isometric3"
import Isometric4 from "./components/isometric/Isometric4"
import Isometric5 from "./components/isometric/Isometric5"

import NonogramExample from "./components/nonogram/NonogramExample"
import NonogramPractice from "./components/nonogram/NonogramPractice"
import NonogramReady from "./components/nonogram/NonogramReady"
import Nonogram1 from "./components/nonogram/Nonogram1"
import Nonogram2 from "./components/nonogram/Nonogram2"
import Nonogram3 from "./components/nonogram/Nonogram3"
import Nonogram4 from "./components/nonogram/Nonogram4"
import Nonogram5 from "./components/nonogram/Nonogram5"

import User from "./pages/user/User"
import DirectionIsometric from "./pages/direction/DirectionIsometric"
import DirectionNonogram from "./pages/direction/DirectionNonogram"
import DirectionBallSort from "./pages/direction/DirectionBallSort"

import PreStudySurvey from "./pages/surveys/PreStudySurvey"

import TrialSurvey1 from "./pages/surveys/TrialSurvey1"
import TrialSurvey2 from "./pages/surveys/TrialSurvey2"
import TrialSurvey3 from "./pages/surveys/TrialSurvey3"

import DifficultySurvey1 from "./pages/surveys/DifficultySurvey1"
import DifficultySurvey2 from "./pages/surveys/DifficultySurvey2"
import DifficultySurvey3 from "./pages/surveys/DifficultySurvey3"
import DifficultySurvey4 from "./pages/surveys/DifficultySurvey4"
import DifficultySurvey5 from "./pages/surveys/DifficultySurvey5"
import DifficultySurvey6 from "./pages/surveys/DifficultySurvey6"
import DifficultySurvey7 from "./pages/surveys/DifficultySurvey7"
import DifficultySurvey8 from "./pages/surveys/DifficultySurvey8"
import DifficultySurvey9 from "./pages/surveys/DifficultySurvey9"
import DifficultySurvey10 from "./pages/surveys/DifficultySurvey10"
import DifficultySurvey11 from "./pages/surveys/DifficultySurvey11"
import DifficultySurvey12 from "./pages/surveys/DifficultySurvey12"
import DifficultySurvey13 from "./pages/surveys/DifficultySurvey13"
import DifficultySurvey14 from "./pages/surveys/DifficultySurvey14"
import DifficultySurvey15 from "./pages/surveys/DifficultySurvey15"
/**
import PreStudySurveyPage from "./pages/surveys/PreStudySurveyPage"
*/

function App(props) {
    const [title, setTitle] = useState(null)

    /**
     * change title on path
     */
    const location = useLocation()
    useEffect(() => {
        const parsedTitle = location.pathname.replace(/\W/g, " ")
        if (parsedTitle === "" || parsedTitle.length === 0) {
            setTitle("Maze")
        } else {
            setTitle(parsedTitle.substring(1, 3))
        }
    }, [location])

    return (
        <>
            <AppBarC text={title} />
            {/**
             * <Navbar />
             * <Logo />
             * <BigTitle text={title} top="10%" left="5%" />
             * <ParticlesBG text={title} />
             * **/}
            <GridWrapper>
                <Routes key={location.pathname} location={location}>
                    <Route path="/" element={<User />} />

                    <Route path="/error/incognito" element={<NoIncognito />} />
                    <Route path="/error/phone" element={<NoPhone />} />

                    <Route path={finalSurvey1Path} element={<TrialSurvey1 />} />
                    <Route path={finalSurvey2Path} element={<TrialSurvey2 />} />
                    <Route path={finalSurvey3Path} element={<TrialSurvey3 />} />

                    <Route
                        path={ballSortDirectionPath}
                        element={<DirectionBallSort />}
                    />
                    <Route
                        path={ballSortPracticePath}
                        element={<BallSortPractice />}
                    />
                    <Route
                        path={ballSortReadyPath}
                        element={<BallSortReady />}
                    />

                    <Route
                        path={ballSortExamplePath}
                        element={<BallSortExample />}
                    />
                    <Route path={ballSort1Path} element={<BallSort4 />} />
                    <Route path={ballSort2Path} element={<BallSort5 />} />
                    <Route path={ballSort3Path} element={<BallSort6 />} />
                    <Route path={ballSort4Path} element={<BallSort7 />} />
                    <Route path={ballSort5Path} element={<BallSort8 />} />

                    <Route
                        path={ifDirectionPath}
                        element={<DirectionIsometric />}
                    />
                    <Route path={ifExamplePath} element={<IfExample />} />
                    <Route path={ifPracticePath} element={<IfPractice />} />
                    <Route path={ifReadyPath} element={<IsometricReady />} />

                    <Route path={if1Path} element={<Isometric1 />} />
                    <Route path={if2Path} element={<Isometric2 />} />
                    <Route path={if3Path} element={<Isometric3 />} />
                    <Route path={if4Path} element={<Isometric4 />} />
                    <Route path={if5Path} element={<Isometric5 />} />

                    <Route
                        path={nonogramDirectionPath}
                        element={<DirectionNonogram />}
                    />
                    <Route
                        path={nonogramExamplePath}
                        element={<NonogramExample />}
                    />
                    <Route
                        path={nonogramPracticePath}
                        element={<NonogramPractice />}
                    />
                    <Route
                        path={nonogramReadyPath}
                        element={<NonogramReady />}
                    />

                    <Route path={nonogram1Path} element={<Nonogram1 />} />
                    <Route path={nonogram2Path} element={<Nonogram2 />} />
                    <Route path={nonogram3Path} element={<Nonogram3 />} />
                    <Route path={nonogram4Path} element={<Nonogram4 />} />
                    <Route path={nonogram5Path} element={<Nonogram5 />} />

                    <Route
                        path={preStudySurveyPath}
                        element={<PreStudySurvey />}
                    />
                    <Route
                        path={difficultySurvey1Path}
                        element={<DifficultySurvey1 />}
                    />
                    <Route
                        path={difficultySurvey2Path}
                        element={<DifficultySurvey2 />}
                    />
                    <Route
                        path={difficultySurvey3Path}
                        element={<DifficultySurvey3 />}
                    />
                    <Route
                        path={difficultySurvey4Path}
                        element={<DifficultySurvey4 />}
                    />
                    <Route
                        path={difficultySurvey5Path}
                        element={<DifficultySurvey5 />}
                    />
                    <Route
                        path={difficultySurvey6Path}
                        element={<DifficultySurvey6 />}
                    />
                    <Route
                        path={difficultySurvey7Path}
                        element={<DifficultySurvey7 />}
                    />
                    <Route
                        path={difficultySurvey8Path}
                        element={<DifficultySurvey8 />}
                    />
                    <Route
                        path={difficultySurvey9Path}
                        element={<DifficultySurvey9 />}
                    />
                    <Route
                        path={difficultySurvey10Path}
                        element={<DifficultySurvey10 />}
                    />
                    <Route
                        path={difficultySurvey11Path}
                        element={<DifficultySurvey11 />}
                    />
                    <Route
                        path={difficultySurvey12Path}
                        element={<DifficultySurvey12 />}
                    />
                    <Route
                        path={difficultySurvey13Path}
                        element={<DifficultySurvey13 />}
                    />
                    <Route
                        path={difficultySurvey14Path}
                        element={<DifficultySurvey14 />}
                    />
                    <Route
                        path={difficultySurvey15Path}
                        element={<DifficultySurvey15 />}
                    />
                </Routes>
            </GridWrapper>

            {/**
            <Footer />
            */}
        </>
    )
}

export default App
