import React from "react"
import { Box, AppBar, Toolbar } from "@mui/material"
import BasicCard from "../common/basiccard/BasicCard"
import axios from "axios"

import "./styles/index.css"
import { difficultySurvey15Path } from "../../config/redirectPath"
import { DoneButton } from "../common/buttons/DoneButton"
import { ResetButton } from "../common/buttons/ResetButton"
import { UndoButton } from "../common/buttons/UndoButton"
import { RedoButton } from "../common/buttons/RedoButton"

const baseUrl = "/api/nanograms"
const QID = "nonogram5"
const SIZE = 12
const NextPagePath = difficultySurvey15Path

const squareButtonClassNameFilled = "square-button-filled-" + SIZE
const squareButtonClassNameEmpty = "square-button-empty-" + SIZE

function toIndex(row, col) {
    return row * SIZE + col
}

function newPuzzle() {
    let puzzle = Array(SIZE * SIZE).fill(false)
    puzzle[toIndex(0, 0)] = true
    puzzle[toIndex(0, 4)] = true
    puzzle[toIndex(1, 1)] = true
    puzzle[toIndex(1, 3)] = true
    puzzle[toIndex(1, 6)] = true
    puzzle[toIndex(1, 10)] = true
    puzzle[toIndex(2, 2)] = true
    puzzle[toIndex(2, 7)] = true
    puzzle[toIndex(2, 9)] = true
    puzzle[toIndex(3, 1)] = true
    puzzle[toIndex(3, 2)] = true
    puzzle[toIndex(3, 3)] = true
    puzzle[toIndex(3, 8)] = true
    puzzle[toIndex(4, 0)] = true
    puzzle[toIndex(4, 2)] = true
    puzzle[toIndex(4, 4)] = true
    puzzle[toIndex(4, 7)] = true
    puzzle[toIndex(4, 8)] = true
    puzzle[toIndex(4, 9)] = true
    puzzle[toIndex(5, 2)] = true
    puzzle[toIndex(5, 6)] = true
    puzzle[toIndex(5, 8)] = true
    puzzle[toIndex(5, 10)] = true
    puzzle[toIndex(6, 1)] = true
    puzzle[toIndex(6, 2)] = true
    puzzle[toIndex(6, 3)] = true
    puzzle[toIndex(6, 4)] = true
    puzzle[toIndex(6, 8)] = true
    puzzle[toIndex(7, 2)] = true
    puzzle[toIndex(7, 3)] = true
    puzzle[toIndex(7, 8)] = true
    puzzle[toIndex(8, 2)] = true
    puzzle[toIndex(8, 3)] = true
    puzzle[toIndex(8, 6)] = true
    puzzle[toIndex(8, 7)] = true
    puzzle[toIndex(8, 8)] = true
    puzzle[toIndex(8, 9)] = true
    puzzle[toIndex(8, 10)] = true
    puzzle[toIndex(9, 2)] = true
    puzzle[toIndex(9, 3)] = true
    puzzle[toIndex(9, 7)] = true
    puzzle[toIndex(9, 8)] = true
    puzzle[toIndex(9, 9)] = true
    puzzle[toIndex(10, 0)] = true
    puzzle[toIndex(10, 1)] = true
    puzzle[toIndex(10, 2)] = true
    puzzle[toIndex(10, 3)] = true
    puzzle[toIndex(10, 4)] = true
    puzzle[toIndex(10, 5)] = true
    puzzle[toIndex(10, 6)] = true
    puzzle[toIndex(10, 7)] = true
    puzzle[toIndex(10, 8)] = true
    puzzle[toIndex(10, 9)] = true
    puzzle[toIndex(10, 10)] = true
    puzzle[toIndex(10, 11)] = true
    puzzle[toIndex(11, 1)] = true
    puzzle[toIndex(11, 2)] = true
    puzzle[toIndex(11, 3)] = true
    puzzle[toIndex(11, 4)] = true
    puzzle[toIndex(11, 5)] = true
    puzzle[toIndex(11, 6)] = true
    puzzle[toIndex(11, 7)] = true
    puzzle[toIndex(11, 8)] = true
    puzzle[toIndex(11, 9)] = true
    puzzle[toIndex(11, 10)] = true
    let guesses = Array(SIZE * SIZE).fill(false)
    return { puzzle, guesses }
}

function Clue(props) {
    let puzzle, guesses, separator, className
    if (props.orientation === "row") {
        // a row clue
        puzzle = Array.from(
            Array(SIZE),
            (_, col) => props.puzzle[toIndex(props.index, col)]
        )
        guesses = Array.from(
            Array(SIZE),
            (_, col) => props.guesses[toIndex(props.index, col)]
        )
        separator = " "
        className = "clue-row"
    } else {
        // a column clue
        puzzle = Array.from(
            Array(SIZE),
            (_, row) => props.puzzle[toIndex(row, props.index)]
        )
        guesses = Array.from(
            Array(SIZE),
            (_, row) => props.guesses[toIndex(row, props.index)]
        )
        separator = <br />
        className = "clue-col"
    }

    let count = 0
    for (let col = 0; col <= SIZE; col++) {
        if (puzzle[col] == true) {
            count = count + 1
        }
    }

    return (
        <td className={className}>
            <span className={"clue"}>{count}</span>
        </td>
    )
}

function Square(props) {
    let squareFilled = false
    if (props.guess === true) {
        squareFilled = true
    }
    let body = null
    let squareClassName = squareFilled ? "square-filled" : "square-empty"
    let squareButtonClassName = squareFilled
        ? squareButtonClassNameFilled
        : squareButtonClassNameEmpty
    return (
        <td className={squareClassName}>
            <button
                className={squareButtonClassName}
                onClick={props.onClick}
                onContextMenu={props.onClick}
            >
                {body}
            </button>
        </td>
    )
}

function Puzzle(props) {
    let rows = [
        <tr key={0}>
            <td></td>
            {Array.from(Array(SIZE), (_, col) => (
                <Clue
                    key={"cc" + col}
                    orientation="column"
                    index={col}
                    puzzle={props.puzzle}
                    guesses={props.guesses}
                />
            ))}
        </tr>,
    ]

    for (let row = 0; row < SIZE; row++) {
        rows.push(
            <tr key={row + 1}>
                <Clue
                    key={"cr" + row}
                    orientation="row"
                    index={row}
                    puzzle={props.puzzle}
                    guesses={props.guesses}
                />
                {Array.from(Array(SIZE), (_, col) => (
                    <Square
                        key={row + "," + col}
                        coin={props.puzzle[toIndex(row, col)]}
                        guess={props.guesses[toIndex(row, col)]}
                        onClick={(event) =>
                            props.onSquareClick(event, row, col)
                        }
                    />
                ))}
            </tr>
        )
    }

    return (
        <table className="puzzle">
            <tbody>{rows}</tbody>
        </table>
    )
}

class Nonogram5 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            rowClues: [],
            colClues: [],

            startTime: Date.now(),
            startTimeStr: Date().toLocaleString(),

            numClicks: 0,
            numUndos: 0,
            numRedos: 0,
            numResets: 0,

            gameState: [],

            actions: [],
            actionIndex: [],
            actionTimes: [],

            pastActions: [],
            pastResets: [],
            pastUndos: [],
        }
        Object.assign(this.state, newPuzzle())
    }

    getRowClue(row) {
        return this.state.rowClues[row]
    }

    getColClue(col) {
        return this.state.colClues[col]
    }

    onSquareClick = (event, row, col) => {
        let index = toIndex(row, col)

        let timeNow = Date.now() - this.state.startTime
        let timeNowString = timeNow.toString() + ","

        if (!this.state.guesses[index]) {
            let guesses = this.state.guesses.slice()

            guesses[index] = true
            this.setState({ guesses })
            this.setState((prevState) => ({
                numClick: this.state.numClick + 1,
                pastActions: [...prevState.pastActions, index],
                gameState: [...prevState.gameState, guesses.toString()],

                actions: [...prevState.actions, "fill"],
                actionIndex: [...prevState.actionIndex, index],
                actionTimes: [...prevState.actionTimes, timeNowString],
            }))
        } else {
            let guesses = this.state.guesses.slice()
            guesses[index] = false
            this.setState({ guesses })

            this.setState((prevState) => ({
                numClick: this.state.numClick + 1,
                pastActions: [...prevState.pastActions, index],
                gameState: [...prevState.gameState, guesses.toString()],

                actions: [...prevState.actions, "unfill"],
                actionIndex: [...prevState.actionIndex, index],
                actionTimes: [...prevState.actionTimes, timeNowString],
            }))
        }
    }

    onUndoClick = () => {
        let timeNow = Date.now() - this.state.startTime
        let timeNowString = timeNow.toString() + ","

        if (this.state.pastActions.length >= 1) {
            let index = this.state.pastActions.pop()

            if (index === -1) {
                let beforeReset = this.state.pastResets.pop().slice()
                this.setState({ guesses: beforeReset })

                this.setState((prevState) => ({
                    numUndos: this.state.numUndos + 1,
                    gameState: [...prevState.gameState, beforeReset.toString()],

                    pastUndos: [...prevState.pastUndos, -1],

                    actions: [...prevState.actions, "undo-reset"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            } else if (!this.state.guesses[index]) {
                let guesses = this.state.guesses.slice()
                guesses[index] = true
                this.setState({ guesses })
                this.setState((prevState) => ({
                    numUndos: this.state.numUndos + 1,
                    gameState: [...prevState.gameState, guesses.toString()],
                    pastUndos: [...prevState.pastUndos, index],
                    actions: [...prevState.actions, "undo-fill"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            } else {
                let guesses = this.state.guesses.slice()
                guesses[index] = false
                this.setState({ guesses })
                this.setState((prevState) => ({
                    numUndos: this.state.numUndos + 1,
                    gameState: [...prevState.gameState, guesses.toString()],
                    pastUndos: [...prevState.pastUndos, index],
                    actions: [...prevState.actions, "undo-unfill"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            }
        } else {
            console.log("no more to undo")
            this.setState((prevState) => ({
                numUndos: this.state.numUndos + 1,
                gameState: [
                    ...prevState.gameState,
                    this.state.guesses.toString(),
                ],

                actions: [...prevState.actions, "undo-failed"],
                actionIndex: [...prevState.actionIndex, -1],
                actionTimes: [...prevState.actionTimes, timeNowString],
            }))
        }
    }

    onRedoClick = () => {
        let timeNow = Date.now() - this.state.startTime
        let timeNowString = timeNow.toString() + ","

        if (this.state.pastUndos.length >= 1) {
            let index = this.state.pastUndos.pop()

            if (index === -1) {
                this.setState((prevState) => ({
                    pastResets: [...prevState.pastResets, this.state.guesses],
                }))

                let guesses = Array(SIZE * SIZE).fill(false)

                this.setState({ guesses })
                this.setState((prevState) => ({
                    numRedos: this.state.numRedos + 1,
                    gameState: [...prevState.gameState, guesses.toString()],

                    pastActions: [...prevState.pastActions, -1],

                    actions: [...prevState.actions, "redo-reset"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            } else if (!this.state.guesses[index]) {
                let guesses = this.state.guesses.slice()
                guesses[index] = true
                this.setState({ guesses })
                this.setState((prevState) => ({
                    numRedos: this.state.numRedos + 1,
                    gameState: [...prevState.gameState, guesses.toString()],

                    pastActions: [...prevState.pastActions, index],

                    actions: [...prevState.actions, "redo-fill"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            } else {
                let guesses = this.state.guesses.slice()
                guesses[index] = false
                this.setState({ guesses })
                this.setState((prevState) => ({
                    numRedos: this.state.numRedos + 1,
                    gameState: [...prevState.gameState, guesses.toString()],

                    pastActions: [...prevState.pastActions, index],

                    actions: [...prevState.actions, "redo-unfill"],
                    actionIndex: [...prevState.actionIndex, index],
                    actionTimes: [...prevState.actionTimes, timeNowString],
                }))
            }
        } else {
            console.log("no more to redo")
            this.setState((prevState) => ({
                numRedos: this.state.numRedos + 1,
                gameState: [
                    ...prevState.gameState,
                    this.state.guesses.toString(),
                ],

                actions: [...prevState.actions, "redo-failed"],
                actionIndex: [...prevState.actionIndex, -1],
                actionTimes: [...prevState.actionTimes, timeNowString],
            }))
        }
    }

    onResetClick = () => {
        let timeNow = Date.now() - this.state.startTime
        let timeNowString = timeNow.toString() + ","

        this.setState((prevState) => ({
            pastResets: [...prevState.pastResets, this.state.guesses],
        }))

        let guesses = Array(SIZE * SIZE).fill(false)

        this.setState({ guesses })
        this.setState((prevState) => ({
            numResets: this.state.numResets + 1,

            pastActions: [...prevState.pastActions, -1],
            gameState: [...prevState.gameState, guesses.toString()],

            actions: [...prevState.actions, "reset"],
            actionIndex: [...prevState.actionIndex, -1],
            actionTimes: [...prevState.actionTimes, timeNowString],
        }))
    }

    onDoneClick = () => {
        let data = {
            userId: localStorage.getItem("user_id"),
            q_id: QID,

            numClicks: this.state.numClicks,
            numUndos: this.state.numUndos,
            numRedos: this.state.numRedos,
            numResets: this.state.numResets,

            userResponse: this.state.guesses.toString(),
            allPastGameStates: this.state.gameState.toString(),

            actions: this.state.actions.toString(),
            actionIndex: this.state.actionIndex.toString(),
            actionTimes: this.state.actionTimes.toString(),

            startTime: this.state.startTimeStr.toString(),
            endTime: Date().toLocaleString().toString(),
        }

        ;(async function () {
            await axios.post(`${baseUrl}/`, data).then((response) => {
                window.location.assign(NextPagePath)
            })
        })()
    }
    render() {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                    }}
                >
                    <UndoButton
                        onClick={this.onUndoClick}
                        disabled={this.state.pastActions.length === 0}
                    />
                    <RedoButton
                        onClick={this.onRedoClick}
                        disabled={this.state.pastUndos.length === 0}
                    />
                </Box>
                <Box
                    sx={{
                        border: "2px solid #888",
                        borderRadius: "5px",
                        padding: "10px",
                        backgroundColor: "#eee",
                    }}
                >
                    <Puzzle
                        puzzle={this.state.puzzle}
                        guesses={this.state.guesses}
                        onSquareClick={this.onSquareClick}
                    />
                </Box>
                <AppBar
                    position="fixed"
                    color="primary"
                    sx={{ top: "auto", bottom: 0 }}
                >
                    <Toolbar sx={{ justifyContent: "center" }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 4,
                            }}
                        >
                            <ResetButton
                                onClick={this.onResetClick}
                                disabled={
                                    this.state.guesses.toString() ===
                                    newPuzzle().guesses.toString()
                                }
                            />
                            <DoneButton
                                onClick={this.onDoneClick} // Disable onClick if completed
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        )
    }
}

export default Nonogram5
