import React from "react"
import { Button, Box, AppBar, Toolbar, Typography } from "@mui/material"

import "./styles/direction.css"
import { nonogramPracticePath } from "../../config/redirectPath"
import BasicCard from "../../components/common/basiccard/BasicCard"

const baseImgUrl = "/assets/"
const handleNext = () => {
    let nextPagePath = nonogramPracticePath
    window.location.assign(nextPagePath)
}

const DirectionNonograms = () => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "80vh",
                    gap: 4,
                }}
            >
                <BasicCard
                    content={
                        <>
                            <video
                                src={baseImgUrl + "Nonograms Instructions 5.6.24.mp4"}
                                poster={baseImgUrl + "Nonograms Instructions 5.6.24.jpg"}
                                controls
                                className="video-size-isometric"
                            >
                                Your browser does not support the video tag.
                            </video>
                        </>
                    }
                />
            </Box>
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0 }}
            >
                <Toolbar sx={{ justifyContent: "center" }}>
                    <Button onClick={handleNext}>Next</Button>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default DirectionNonograms